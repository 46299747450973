export interface Category {
  title: string;
  value: string;
  image: string;
  color: string;
}
export const Categories = [
  {
    title: "Arts & Literature",
    value:
      "arts,literature,arts_and_literature,novels,movies,film,film_and_tv,music,society_and_culture,society,culture",
    image:
      "https://cdn.pixabay.com/photo/2015/05/15/09/13/demonstration-767982__340.jpg",
    color: "rgb(220,0,95)",
    // color: "rgba(255,105,180, 0.6)",
  },
  {
    title: "General Knowledge",
    value: "food_and_drink,food,drink,alcohol,general_knowledge",
    image:
      "https://cdn.pixabay.com/photo/2022/11/05/02/15/lightning-7570980__340.jpg",
    color: "rgb(165,0,145)",
    // color: "rgba(153,50,204, 0.6)",
  },
  {
    title: "Sport & Leisure",
    value: "sport_and_leisure,sports,sport,games",
    image:
      "https://cdn.pixabay.com/photo/2016/07/26/12/05/olympia-1542700__340.jpg",
    color: "rgb(224,130,0)",
    // color: "rgba(255,69,0, 0.6)",
  },
  {
    title: "Science",
    value: "science",
    image:
      "https://cdn.pixabay.com/photo/2016/07/23/10/51/binary-1536651__340.jpg",
    color: "rgb(76,161,0)",
    // color: "rgba(60,179,113, 0.6)",
  },
  {
    title: "Geography",
    value: "geography",
    image: "https://cdn.pixabay.com/photo/2015/01/10/23/04/map-595791__340.png",
    color: "rgb(0,113,207)",
    // color: "rgba(0,0,255, 0.5)",
  },
  {
    title: "History",
    value: "history",
    image:
      "https://cdn.pixabay.com/photo/2015/11/04/21/41/painting-1023411__340.jpg",
    // color: "rgb(255,255,0)",
    color: "rgb(230,218,32)",
    // color: "rgba(255,255,0, 0.5)",
  },
];

export interface PlayerCategories {
  ArtsLiterature: boolean;
  GeneralKnowledge: boolean;
  SportLeisure: boolean;
  Science: boolean;
  Geography: boolean;
  History: boolean;
}

export interface Player {
  color: string;
  categories: PlayerCategories;
}
