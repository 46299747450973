import { FC } from "react";
import { Categories, Category } from "../types/types";

const Select: FC<{
  title: string;
  mode: string;
  changeTab: (path: string) => void;
  setCategory: (cat: Category) => void;
  setMode: (mode: string) => void;
}> = ({ title, mode, changeTab, setCategory, setMode }) => {
  return (
    <div className="container">
      <h2
        style={{
          display: "flex",
          gap: 20,
        }}
      >
        Select Category
        <select
          id="difficulty"
          value={mode}
          onChange={(event) => setMode(event.target.value)}
        >
          <option value="easy">easy</option>
          <option value="medium">medium</option>
          <option value="hard">hard</option>
        </select>
      </h2>
      {Categories && (
        <ul className="select">
          {Categories.map((item, i) => (
            <li
              style={{
                backgroundColor: item.color,
              }}
              onClick={() => {
                changeTab("quiz");
                setCategory(item);
              }}
              key={i}
            >
              <p>{item.title}</p>
              <div className="thumb">
                <img src={item.image} alt="" />
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Select;
