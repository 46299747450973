import Konva from "konva";
import { Stage } from "konva/lib/Stage";
import { FC, useEffect } from "react";

export const Board: FC<{ playerCount: number }> = ({ playerCount }) => {
  const addPlayer = (stage: Stage, color: string) => {
    var rectX = stage.width() / 2 - 50;
    var rectY = stage.height() / 2 - 25;

    var box = new Konva.Circle({
      x: rectX,
      y: rectY,
      width: 30,
      height: 30,
      fill: color,
      stroke: "black",
      strokeWidth: 4,
      draggable: true,
    });

    // add cursor styling
    box.on("mouseover", function () {
      document.body.style.cursor = "pointer";
    });
    box.on("mouseout", function () {
      document.body.style.cursor = "default";
    });

    return box;
  };

  useEffect(() => {
    var width = 600;
    var height = 600;

    var stage = new Konva.Stage({
      container: "container",
      width: width,
      height: height,
    });

    var layer = new Konva.Layer();

    const player1 = addPlayer(stage, "rgb(255,255,0)");

    layer.add(player1);
    const player2 = addPlayer(stage, "rgb(0,0,255)");

    layer.add(player2);
    stage.add(layer);
  }, []);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <img width={600} height={600} src={require("../img/board.jpg")} alt="" />

      <div
        id="container"
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          zIndex: 3,
        }}
      />
    </div>
  );
};
