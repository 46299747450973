import { FC, Fragment, useState } from "react";
import useFetch from "../customHooks/useFetch";
import { Category } from "../types/types";

const Quiz: FC<{
  category?: Category;
  modePath: any;
  changeTab: (path: string) => void;
}> = ({ category, modePath, changeTab }) => {
  const { data, isPending, error } = useFetch(
    `https://the-trivia-api.com/api/questions?categories=${category?.value}&limit=1&difficulty=${modePath}`
  );
  const [quizEnd, setQuizEnd] = useState(false);
  let correctAnswers: any[] = [];
  let shuffledKeys: any[] = [];

  const setAnswer = (e: any) => {
    e.target.firstElementChild.checked = true;
  };

  const shuffleAnswers = (correct: any, aIncorrect: any[], id: string) => {
    let answers: [{ correct?: string; inc?: string }] = [{ correct: correct }];
    correctAnswers.push(correct);
    aIncorrect.map((inc) => answers.push({ inc: inc }));

    let shuffled = answers
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

    let shuffledValues: any[] = [];

    shuffled.forEach(function (obj) {
      for (var key in obj) {
        shuffledValues.push((obj as any)[key]);
        shuffledKeys.push(key);
      }
    });

    shuffledKeys.push("inc");

    return (
      <Fragment>
        {shuffledValues.map((sItem) => (
          <li onClick={(e) => setAnswer(e)} key={sItem}>
            <input
              className="answer"
              onClick={(e) => e.stopPropagation}
              type="radio"
              name={id}
              value={sItem}
            />
            {sItem}
          </li>
        ))}
      </Fragment>
    );
  };

  const submitQuiz = () => {
    let renderedAnswers = document.querySelectorAll(".answer");
    let selectedAnswers: any[] = [];

    renderedAnswers.forEach((element, i) => {
      (element?.parentNode as any).classList.add("disable-radio");

      if ((element as any)?.checked) {
        selectedAnswers.push(element);
      }

      if (shuffledKeys[i] === "correct") {
        (element.parentNode as any).classList.add("correct");
      }
    });

    selectedAnswers.forEach((element, i) => {
      if (element.value === correctAnswers[i]) {
        element.parentNode.classList.add("yes-point");
      } else {
        element.parentNode.classList.add("no-point");
      }
    });

    window.scrollTo({ top: 0, behavior: "smooth" });

    setTimeout(() => {
      setQuizEnd(true);
    }, 100);
  };

  return (
    <div className="container">
      <h1 style={{ color: category?.color }}>
        {`${category?.title} - ${modePath}`}
      </h1>
      {isPending && <div>Loading . . .</div>}
      {error && <div>{error}</div>}
      {data && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ paddingLeft: 50 }} className="quiz">
            {(data as any).map((question: any) => (
              <div className="item" key={question.id}>
                <p className="question">{question.question}</p>
                <ol className="answers">
                  {shuffleAnswers(
                    question.correctAnswer,
                    question.incorrectAnswers,
                    question.id
                  )}
                </ol>
              </div>
            ))}
          </div>
          {!quizEnd && (
            <div className="submit">
              <button onClick={submitQuiz}>Submit</button>
            </div>
          )}
          {quizEnd && (
            <div className="submit">
              <button onClick={() => changeTab("select")}>back</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Quiz;
