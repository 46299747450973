import { FC, useEffect, useState } from "react";
import "./App.css";

import Quiz from "./components/Quiz";
import Select from "./components/Select";
import { Category, Player, PlayerCategories } from "./types/types";
import { Board } from "./components/Board";

function App() {
  const [tab, setTab] = useState("select");
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [modePath, setModePath] = useState("medium");
  const [players, setPlayers] = useState<Player[]>([]);

  const changeTab = (tabName: any) => {
    setTab(tabName);
  };

  const setCategory = (category: Category) => {
    setSelectedCategory(category);
  };

  const setMode = (mode: string) => {
    setModePath(mode);
  };

  useEffect(() => {
    setPlayers([
      {
        color: "rgb(255,255,0)",
        categories: {
          ArtsLiterature: false,
          GeneralKnowledge: false,
          SportLeisure: false,
          Science: false,
          Geography: false,
          History: false,
        },
      },
      {
        color: "rgb(0,0,255)",
        categories: {
          ArtsLiterature: false,
          GeneralKnowledge: false,
          SportLeisure: false,
          Science: false,
          Geography: false,
          History: false,
        },
      },
    ]);
  }, []);

  return (
    <div className="App">
      <h1>{"Trivial Pursuit Questions"}</h1>
      <div className="content">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Board playerCount={2} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            {players.map((p, i) => (
              <PlayerCategory
                key={i}
                player={p}
                playerNumber={i + 1}
                onChange={(player) => {
                  const playersCopy = [...players];
                  playersCopy[i] = player;
                  setPlayers(playersCopy);
                }}
              />
            ))}
          </div>
        </div>
        <iframe
          style={{
            border: "0px",
          }}
          width={200}
          height={300}
          title="die"
          src="./test.html"
        />
        {tab === "select" ? (
          <Select
            title="Trivial Pursuit Questions"
            changeTab={changeTab}
            setCategory={setCategory}
            setMode={setMode}
            mode={modePath}
          />
        ) : null}

        {tab === "quiz" ? (
          <Quiz
            changeTab={changeTab}
            category={selectedCategory}
            modePath={modePath}
          />
        ) : null}
      </div>
    </div>
  );
}

const PlayerCategory: FC<{
  player: Player;
  playerNumber: number;
  onChange: (player: Player) => void;
}> = ({ player, playerNumber, onChange }) => {
  return (
    <div>
      <span
        style={{
          border: `2px solid ${player.color}`,
        }}
      >{`Player ${playerNumber}`}</span>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {Object.keys(player.categories).map((cat) => (
          <div key={cat}>
            <input
              type="checkbox"
              checked={player.categories[cat as keyof PlayerCategories]}
              onChange={() => {
                player.categories[cat as keyof PlayerCategories] =
                  !player.categories[cat as keyof PlayerCategories];
                onChange(player);
              }}
            />
            <label>
              {cat
                .match(/[A-Z][a-z]+/g)
                ?.join(cat === "GeneralKnowledge" ? " " : " & ")}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;
